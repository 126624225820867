<template>
  <form
    id="app"
    @submit="checkForm"
    action="/"
    method="post"
  >

    <div v-for="audioItem in audioItems" :key="audioItem.url">
      <textarea v-model="audioItem.url" />
      <button @click="removeItem(audioItem)">Remove</button>
    </div>

    <button @click="addAudioItem">
      New Track
    </button>

    <button @click="load">Load</button>
    <button @click="save">Save</button>
    <button @click="reset">Reset</button>
  </form>
</template>

<script>

export default {
  name: 'ProjectSettings',
  methods: {
    checkForm: function (e) {
      e.preventDefault();
    },
    addAudioItem: function () {
      this.audioItems.push({ url: '' });
    },
    removeItem(audioItem) {
      const index = this.audioItems.findIndex((element) =>  element.url == audioItem.url);
      this.audioItems.splice(index, 1);
    },
    save() {
      localStorage.projectSettings = JSON.stringify({ audioItems: this.audioItems });
    },
    load() {
      let data = JSON.parse(localStorage.projectSettings);
      if (!data.audioItems) return ;
      this.$store.projectSettings.audioItems.length = 0;
      this.$store.projectSettings.audioItems.push(...data.audioItems);
    },
    reset() {
      let data = [{ url: "https://raw.githubusercontent.com/naomiaro/waveform-playlist/master/dist/waveform-playlist/media/audio/Guitar30.mp3" }, 
            { url: "https://raw.githubusercontent.com/naomiaro/waveform-playlist/master/dist/waveform-playlist/media/audio/BassDrums30.mp3" }];
      this.$store.projectSettings.audioItems.length = 0;
      this.$store.projectSettings.audioItems.push(...data);
    }
  },
  data: function() {
    return { 
      name: "test",
      audioItems: this.$store.projectSettings.audioItems
    }
  },
  mounted: function (){ }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
