<template>
  <div class="about">
    <ProjectSettings />
  </div>
</template>

<script>
// @ is an alias to /src
import ProjectSettings from '@/components/ProjectSettings.vue'

export default {
  name: 'About',
  components: {
    ProjectSettings
  }
}
</script>
